export const uploadFile = async (url: string, file: BodyInit) => {
  await fetch(url, {
    method: 'PUT',
    body: file,
  });
};

const trimRegex = /^"(.*)"$/;
export const readCSVFile = (file: Blob): Promise<string[][]> => {
  return new Promise<string[][]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = () =>
      resolve(
        ((reader.result as string)
          .split('\n')
          .slice(1)
          .map((row) => row.split(','))
          .map((row) => {
            return row.map((cell) => {
              const match = cell.match(trimRegex);
              const result = match ? match[1] : cell;
              return result.trim();
            });
          }) as string[][]) || []
      );
    reader.readAsText(file);
  });
};

export const dataUriToBlob = (dataUri: string): BodyInit => {
  const byteString = atob(dataUri.split(',')[1]);
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
